import React, {Fragment} from "react"


import '../../scss/switchButton.scss'

class SwitchButton extends React.Component {
    constructor(props) {
        super(props);
        this.state = {clicked: false};
    }

    click(){
        if(this.props.onClick){
            this.props.onClick();
        }
        this.setState({
            clicked: !this.state.clicked,
        })
    }

    render(){
    return(
    <div onClick={() => this.click()}className={this.state.clicked ? "switch--button active" : "switch--button"}>
        <img className={this.state.clicked ? "switch--icon active" : "switch--icon"} src={!this.state.clicked ? this.props.icon : (this.props.activeIcon || this.props.icon)}/>
        <p className={(this.state.clicked && !this.props.static) ? "switch--text active" : "switch--text"}>{!this.state.clicked ? this.props.text : (this.props.activeText || this.props.text)}</p>
    </div>
    );
    }
}

export default SwitchButton;
