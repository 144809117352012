import React from "react"

import WOW from 'wowjs';
import { Helmet } from "react-helmet"
import { findDOMNode } from "react-dom";
import * as yup from 'yup';

import UserContext from "../userContext.js"

import Footer from '../components/footer'
import Countdown from "react-countdown";

import LogoSlider from "../components/logoSlider.jsx";

import { Link } from "gatsby";

import UserTopbar from "../components/userTopbar";

import ilustration4 from '../images/ilustration-4.png'

import grid1 from '../images/grid_1.png'
import grid2 from '../images/grid_2.png'

import mt1 from '../images/t1.png'
import mt2 from '../images/t2.png'
import mt3 from '../images/t3.png'
import mt4 from '../images/t4.png'
import mt5 from '../images/t5.png'
import mt6 from '../images/t6.png'
import mt7 from '../images/t7.png'
import mt8 from '../images/t8.png'
import mt9 from '../images/t9.png'

import msj_cup from '../images/msj_cup.svg'
import msj_onboarding from '../images/msj_onboarding.svg'

import u1 from '../images/user_1.png'
import u2 from '../images/user_2.png'
import u3 from '../images/user_3.png'
import u7 from '../images/u7.png'

import filter from '../images/filter_icon.png'


import '../scss/feed.scss'
import 'semantic-ui-css/semantic.min.css'
import Selectable from "../components/selectable/index.jsx";
import SwitchButton from "../components/switchButton/index.jsx";

export default class feed extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      following: false,
      step: 0,
      player: false,
      client: false,
    };
    this.references = {
      hero: React.createRef(),
      services: React.createRef(),
      numbers: React.createRef(),
      features: React.createRef(),
      logos: React.createRef(),
      contact: React.createRef(),
    }
  }

  componentDidMount() {
    this.setState({
      client: true,
    })
    if (window.location.href.indexOf("onboarding") > -1) {
      this.setState({
        step: 2
      })
    }
    if (window.location.href.indexOf("callback-cup") > -1) {
      this.setState({
        step: 1
      })
    }
  }

  renderer({ days, hours, minutes, seconds, completed }) {
    if (completed) {
      // Render a completed state
      return <p></p>
    } else {
      // Render a countdown
      return <div className="countdown">
        <div className="countdown--time inv">
        </div>
        <div className="countdown--time">
          <span className="number">
            {days}
          </span>
          <span className="text">
          días
          </span>
        </div>
        <div className="countdown--time">
          <span className="number">
            {hours}
          </span>
          <span className="text">
            horas
          </span>
        </div>
        <div className="countdown--time">
          <span className="number">
            {minutes}
          </span>
          <span className="text">
            minutos
          </span>
        </div>
        <div className="countdown--time">
          <span className="number">
            {seconds}
          </span>
          <span className="text">
            segundos
          </span>
        </div>
        <div className="countdown--time inv">
        </div>
      </div>;
    }
  };

  render() {
    return (
      <>
        {this.state.client &&
          <>
            <div className="feed" >
              <UserContext.Consumer>
                {userObject => (
                  <>
                    <UserTopbar onClick={() => this.setState({ player: true })} onClose={() => this.setState({ player: false })} player={this.state.player} user={userObject} profilePic={userObject?.userObject?.picture || null} />
                    <Helmet>
                    </Helmet>
                    <div className="wrapper app">

                      <section className="logos--section">
                        <div className="wrapper wow fadeIn" data-wow-offset="200">
                          <div className="logo--title">
                            <h2 className="title--present">Sumate a la <span>Troop CUP 21</span></h2>
                            <h2 className="text--present">Conectá y conocé a otros jugadores, creá equipos competitivos, conocé nuevos
                              compañeros y competí para convertirte en el gamer que siempre soñaste.</h2>
                          </div>
                          <Countdown
                            daysInHours={false}
                            date={new Date("December 11, 2023 14:00:00")}
                            renderer={this.renderer}
                          />
                          <div className="cup-options">
                          <Link to="/troop-cup-signup" className="button--global yellow">Anotate</Link>
                          <Link to="/troop-cup" className="button--global whiteInvert">Más Información</Link>
                          </div>
                          {/* <LogoSlider/> */}
                        </div>
                      </section>

                      <section className="trooper--section">
                        <div className="linear--header">
                          <h3>
                            Matching troopers
                          </h3>
                          <SwitchButton text={'Búsqueda avanzada'} icon={filter} />
                        </div>
                        <p className="header--p">
                          Estas son algunas sugerencias de otros jugadores con un perfil
                          adecuado o similar al tuyo.
                        </p>
                        <LogoSlider>
                        <Link to={'/camii.fer97'} className="slide">
                            <img className="slide--img" src={u1} />
                          </Link>
                          <Link to={'/camii.fer97'} className="slide">
                            <img className="slide--img" src={u2} />
                          </Link>
                          <Link to={'/camii.fer97'} className="slide">
                            <img className="slide--img" src={u3} />
                          </Link>
                          <Link to={'/camii.fer97'} className="slide">
                            <img className="slide--img" src={u7} />
                          </Link>
                          <Link to={'/camii.fer97'} className="slide">
                            <img className="slide--img" src={u2} />
                          </Link>
                          <Link to={'/camii.fer97'} className="slide">
                            <img className="slide--img" src={u3} />
                          </Link>
                          <Link to={'/camii.fer97'} className="slide">
                            <img className="slide--img" src={u1} />
                          </Link>
                          <Link to={'/camii.fer97'} className="slide">
                            <img className="slide--img" src={u2} />
                          </Link>
                          <Link to={'/camii.fer97'} className="slide">
                            <img className="slide--img" src={u3} />
                          </Link>
                          <Link to={'/camii.fer97'} className="slide">
                            <img className="slide--img" src={u1} />
                          </Link>
                          <Link to={'/camii.fer97'} className="slide">
                            <img className="slide--img" src={u2} />
                          </Link>
                          <Link to={'/camii.fer97'} className="slide">
                            <img className="slide--img" src={u3} />
                          </Link>
                        </LogoSlider>
                      </section>

                      <section className="troops--section">
                        <div className="linear--header">
                          <h3>
                            ¡Troops reclutando!
                          </h3>
                        </div>
                        <p className="header--p">
                          Estas son algunas troops en búsqueda de jugadores con tus
                          características y que podrían interesarte.
                        </p>
                        <LogoSlider>
                          <Link to={'/isurus-gaming'} className="slide">
                            <img className="slide--img" src={mt1} />
                          </Link>
                          <Link to={'/isurus-gaming'} className="slide">
                            <img className="slide--img" src={mt2} />
                          </Link>
                          <Link to={'/isurus-gaming'} className="slide">
                            <img className="slide--img" src={mt3} />
                          </Link>
                          <Link to={'/isurus-gaming'} className="slide">
                            <img className="slide--img" src={mt4} />
                          </Link>
                          <Link to={'/isurus-gaming'} className="slide">
                            <img className="slide--img" src={mt5} />
                          </Link>
                          <Link to={'/isurus-gaming'} className="slide">
                            <img className="slide--img" src={mt6} />
                          </Link>
                          <Link to={'/isurus-gaming'} className="slide">
                            <img className="slide--img" src={mt7} />
                          </Link>
                          <Link to={'/isurus-gaming'} className="slide">
                            <img className="slide--img" src={mt8} />
                          </Link>
                          <Link to={'/isurus-gaming'} className="slide">
                            <img className="slide--img" src={mt9} />
                          </Link>
                        </LogoSlider>
                      </section>


                      <section className="games--section">
                        <div className="wrapper app">
                          <div className="linear--header">
                            <div className="header--text">
                              <h3>
                                Los mejores clips
                              </h3>
                              <p>
                                Mirá los mejores clips compartidos recientemente por troopers.
                              </p>
                            </div>
                            <div className="filtering">
                              <p onClick={() => this.setState({ following: false })} className={!this.state.following ? "link--video active" : "link--video"}>Explorar</p>
                              <p onClick={() => this.setState({ following: true })} className={this.state.following ? "link--video active" : "link--video"}>Siguiendo</p>
                            </div>
                          </div>
                          <img onClick={() => this.setState({player: true})} className="grid" src={this.state.following ? grid1 : grid2} />
        
                        </div>
                      </section>


                    </div>

                    {this.state.step > 0 &&
                      <section ref={this.references.features} className="signup--section">
                        <div className="wrapper">
                          <div className="signup--box">
                            {this.state.step === 1 &&
                              <div className="step step-1">
                                <h2 className="signup--title">¡A competir, trooper!</h2>
                                <img src={msj_cup} />
                              </div>
                            }
                            {this.state.step === 2 &&
                              <div className="step step-1">
                                <h2 className="signup--title">¡Bienvenido <span>{userObject?.userObject?.username || 'User'}</span>!</h2>
                                <img src={msj_onboarding} />
                              </div>
                            }
                            <button className="button--global" onClick={() => this.setState({step: 0})}>{!(this.state.step == 2) ? 'Finalizar' : '¡Empecemos!'}</button>
                          </div>
                        </div>
                      </section>
                    }
                  </>
                  
                )}
              </UserContext.Consumer>
            </div>
            <Footer />
          </>
        }
      </>
    );
  }
}
